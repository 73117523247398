
import Vue from "vue";
import CommentsMixin from "@/modules/orders/mixins/comments";
import Comments from "@/modules/orders/components/Comments.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";

export default Vue.extend({
  name: "AAIJPreviewOrder",

  mixins: [CommentsMixin],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    items: {
      type: Object,
      required: true,
      default: () => ({})
    },
    errorMessages: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  components: {
    Comments,
    ClientData,
    Documents
  },

  data: () => ({
    loading: false as boolean,
    model: {
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "operator",
        client_delegate: "",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any,
      details: {} as any
    } as any,
    comments: {} as any,
    forceKey: 0 as number
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        // @ts-ignore
        this.model = this.value;
      }
    },
    items: {
      immediate: true,
      handler() {
        // @ts-ignore
        this.comments = this.items;
      }
    }
  },

  computed: {
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    }
  },

  methods: {
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;

      if (form.validate()) {
        this.$emit("submit");
      }
    }
  }
});
